:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}

.horizontalSlide {
  animation: slide 15s linear infinite; /* 10s Dauer, lineare Animation, unendlich wiederholen */
  /* background-color: aquamarine; */
  margin: 10px;
}

@keyframes slide {
  from {
    transform: translateX(100%); /* Startposition außerhalb des Sichtbereichs */
  }
  to {
    transform: translateX(-100%); /* Endposition außerhalb des Sichtbereichs */
  }
}

.containerSlide {
  background-color: #4d5567;
  border: 2px solid red;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tags {
  background-color: white;
  text-decoration: none;
  margin: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1rem;
  border-radius: 25px;
  background-color: #695fff;
}


.containerSlide p {
  /* color: green; */
  font-size: 3rem;
  background-color: transparent;
  -webkit-text-stroke: 2px #695fff;
}

.cardGroup {
  margin-bottom: 100px;
}

.CarouselIntervall {
  background-color: #efeff8;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100px;
}

.headerGallery {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 50px;
  margin-bottom: 30px;
  /* background-color: red; */
}

.android {
  margin-bottom: 20px;
  border-radius: 5px;
  width: 20vw;
}

.isNotAndroid {
  margin-bottom: 20px;
  border-radius: 5px;
  width: 50%;
  /* height: 100px; */
}

.heading {
  color: var(--primary-color);
}

.navText h1 {
  margin: 50px;
  color: #695fff;
}

.headerGallery img {
  position: relative;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  height: 30vw;
  width: auto;
}

@media only screen and (max-width: 480px) {
  .android {
    margin-bottom: 50px;
    border-radius: 5px;
    width: 100vw;
  }

  .tags {
    background-color: white;
    text-decoration: none;
    margin: 5px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1rem;
    border-radius: 25px;
    background-color: #695fff;
  }
  

  .isNotAndroid {
    margin-bottom: 100px;
    border-radius: 5px;
    width: 100vw;
  }

  .horizontalSlide {
    animation: slide 15s linear infinite; /* 10s Dauer, lineare Animation, unendlich wiederholen */
    /* background-color: aquamarine; */
    margin: 10px;
  }

  @keyframes slide {
    from {
      transform: translateX(
        100%
      ); /* Startposition außerhalb des Sichtbereichs */
    }
    to {
      transform: translateX(
        -100%
      ); /* Endposition außerhalb des Sichtbereichs */
    }
  }

  .containerSlide {
    background-color: #4d5567;
    border: 2px solid blue;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .containerSlide p {
    /* color: green; */
    font-size: 3rem;
    background-color: transparent;
    -webkit-text-stroke: 2px navy;
  }

  .imageGallery {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: none;

    justify-content: center;
    align-items: center;
  }

  .CarouselIntervall {
    background-color: aqua;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .imageGallery img {
    height: 40vh;
    width: auto;
    margin: 10px;
    border-radius: 5px;
  }

  .gallery {
    /* background-color: blue; */
    display: flex; /* Ändere Display zu Flexbox */
    flex-direction: column; /* Staple die Kinder vertikal */
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    height: max-content; /* 100% der Viewport-Höhe */
  }

  .gallery p {
    font-size: 1rem;
    color: #d3d9e7;
    background-color: #efeff8;
    border-radius: 10px;
    margin-top: 10px;
    width: 80vw; /* Maximalbreite auf 80% der Viewport-Breite beschränken */
    height: max-content;
    padding: 10px;
  }
  .headerGallery {
    display: grid;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-bottom: 30px;
    /* background-color: red; */
  }

  .headerGallery img {
    position: relative;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100vw;
  }
}
