:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}

.gallery {
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  background-color: var(--secondary-color);
  margin-bottom: 150px;
  box-shadow: 0px -6px 12px 5px rgba(0, 0, 0, 0.1);
  width: 100vw;
}

.subheading {

  font-size: 1rem;
}

.galleryContainer {
  font-size: 1rem;
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  background-color: var(--secondary-color);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontal zentrieren */
  justify-content: center; /* Vertikal zentrieren */
}

.galleryContainer p {
  margin-top: 20px;
  width: 50vw;
  text-align: left;
  align-items: center;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontal zentrieren */
  justify-content: center; /* Vertikal zentrieren */
}

.gallery button {
  margin-bottom: 50px;
}

.imageGallery {
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary-color);
  box-shadow: 0px -6px 12px 10px rgba(0, 0, 0, 0.1);
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
}

.imageGallery img {
  height: 30vw;
  width: auto;
  margin: 10px;
  border-radius: 5px;
}

.button {
  background-color: var(--primary-color);
  border: 2px solid transparent;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.button:hover {
  border-color: var(--primary-color);
  background-color: white;
  color: var(--primary-color);
}

.button:active {
  border-color: var(--secondary-color);
  color: var(--primary-color);
}

@media only screen and (max-width: 480px) {
  :root {
    --primary-color: #695fff;
    --secondary-color: #efeff8;
    --accent-color: #4d5567;
  }

  .gallery {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: var(--secondary-color);
    margin-bottom: 20px;
  }

  .galleryContainer {
    font-size: 1rem;
    border-top-right-radius: 00px;
    border-top-left-radius: 00px;
    background-color: var(--secondary-color);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontal zentrieren */
    justify-content: center; /* Vertikal zentrieren */
  }

  .galleryContainer p {
    margin-top: 20px;
    width: 100vw;
    text-align: left;
    align-items: center;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontal zentrieren */
    justify-content: center; /* Vertikal zentrieren */
  }

  .gallery button {
    margin-bottom: 20px;
  }

  .imageGallery {
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-color);

    justify-content: center;
    align-items: center;
  }
  #image_01 {
    margin-top: 30px;
  }

  #image_03 {
    margin-bottom: 30px;
  }

  .imageGallery img {
    margin-top: 100px;
    height: auto;
    width: 80vw;
    margin: 10px;
    border-radius: 5px;
  }

  .button {
    background-color: var(--primary-color);
    border: 2px solid transparent;
  }

  .button:hover {
    border-color: var(--primary-color);
    background-color: white;
    color: var(--primary-color);
  }
}
