:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}

.isNotPanorama img{


  width: 50%;
  border-radius: 5px;
/*Placeholder if is not a panorama image*/
}

.scrollContainer {
  overflow: hidden;
  width: 100vw;
  /* height: 20vw; */
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--primary-color);
  box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
}

.imageContainer {
  /* display: flex; */
  animation: slideAnimation 20s linear infinite;
  width: 50vw;
}

@keyframes slideAnimation {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
}

.container {
  margin-bottom: 100px;
  background-color: var(--primary-color);
  border: 1px dotted var(--primary-color);
}

@media only screen and (max-width: 480px) {
  :root {
    --primary-color: #695fff;
    --secondary-color: #efeff8;
    --accent-color: #4d5567;
  }

  .scrollContainer {
    overflow: hidden;
    /* width: 100vw; */
    height: max-content;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: var(--primary-color);
    box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
  }

  .imageContainer img {
    height: 100vw;
  }

  .imageContainer {
    overflow-x: scroll;
    animation: slideAnimation 30s linear infinite;
    width: 100vw;
  }

  @keyframes slideAnimation {
    0%,
    100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(0);
    }
  }
}
