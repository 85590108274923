.footer {
  height: 20vh;
  width: 100%;
  background-color: #695fff;
  box-shadow: 0px -6px 10px 2px rgba(0, 0, 0, 0.2);
z-index: 100;


}

.contact {
  justify-items: center;
  align-items: center;
  display: grid;
  padding-top: 40px;

  
}

.contact h3 {

  font-size: 1.2rem;
}

#backToTopBtn {
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 5px;
}


@media only screen and (max-width: 480px) {

  .footer {
    height: 26vh;
    width: 100%;
    background-color: #695fff;
    box-shadow: 0px -6px 10px 2px rgba(0, 0, 0, 0.2);
  
  
  }
  
  .contact {
    justify-items: center;
    align-items: center;
    display: grid;
    padding-top: 40px;
    
  }
  
  #backToTopBtn {
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 5px;
  }
  


}