:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}

.projectContainer {
  display: flex;
  justify-content: space-between;
  width: 64vw;
  height: 19vw;
  padding: 20px;
  /* background-color: red; */
  overflow: hidden;
  margin-bottom: 20px;
  color: black;
}

.project {
  padding: 10px;
  width: 20vw;
  /* background-color: red; */
  border-radius: 30px;
  transition: transform 0.3s, box-shadow 0.3s;
}


.project:nth-child(1):hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
 
}

.project:nth-child(2):hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.project:nth-child(3):hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 30vw;
  background-color: var(--secondary-color);
  box-shadow: 0px -6px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 100px 100px 0 0;
  align-items: center;
  justify-content: center;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%; /* Du kannst die Breite nach Bedarf anpassen */
  padding: 20px;
  background-color: aqua;
}



#furtherProjectsText {
  color: var(--primary-color);
  margin: 0; /* Margin auf 0 setzen, um den Standard-Abstand zu entfernen */
  text-align: center;
  align-items: center;
  /* background-color: red; */
  justify-content: center;
}


.button {
  background-color: var(--primary-color);
  border: 2px solid transparent;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center; /* Vertikal zentrieren */
  justify-content: flex-end; /* Horizontal nach rechts ausrichten */
  position: relative;
  left: 40vw;
  bottom: 4vh;
}

.button:hover {
  border-color: var(--primary-color);
  background-color: white;
  color: var(--primary-color);
}

.button:active {
  border-color: var(--secondary-color);
  color: var(--primary-color);
}

.nextProjectBtn:hover {
  border-color: var(--primary-color);
  background-color: white;
  color: var(--primary-color);
}

.nextProjectBtn:active {
  border-color: var(--secondary-color);
  color: var(--primary-color);
}

.nextProjectBtn {
  background-color: var(--primary-color);
  border: 2px solid transparent;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.container img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Oder 'contain' je nach Bedarf */
}

.imageContainer {
  height: 9vw;
  margin: 10px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
}

#furtherProjectsText {

  color: var(--primary-color);
}

@media only screen and (max-width: 480px) {
  :root {
    --primary-color: #695fff;
    --secondary-color: #efeff8;
    --accent-color: #4d5567;
  }

  #furtherProjectsText {

    color: var(--primary-color);
    margin-top: 15px;
  }
  

  .projectContainer {
    display: flex;
    flex-direction: column; /* Änderung hier */
    justify-content: space-between;
    width: max-content;
    height: max-content;
    /* padding: 20px; */
    /* background-color: red; */
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: 20px;
  }



  .project {
    padding: 15px;
    width: 100vw;
    /* background-color: var(--primary-color); */
    border-bottom: 1px solid black;
    /* border-top: 1px solid black; */
    height: max-content;
    border-radius: 0px;
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 10px;
  }

  .project:nth-child(1):hover {
    transform: scale(1.02);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    height: max-content;
   
  }

 
  .project:nth-child(2):hover {
    transform: scale(1.02);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    height: max-content;
  }

  .project:nth-child(3):hover {
    transform: scale(1.02);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    height: max-content;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: max-content;
    background-color: var(--secondary-color);
    box-shadow: 0px -6px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 0 0;
    align-items: center;
    justify-content: center;
  }

  .button {
    background-color: var(--primary-color);
    border: 2px solid transparent;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center; /* Vertikal zentrieren */
    justify-content: flex-end; /* Horizontal nach rechts ausrichten */
    position: relative;
    left: 0vw;
    top: 2vh;
  }

  .button:hover {
    border-color: var(--primary-color);
    background-color: white;
    color: var(--primary-color);
  }

  .button:active {
    border-color: var(--secondary-color);
    color: var(--primary-color);
  }

  .nextProjectBtn:hover {
    border-color: var(--primary-color);
    background-color: white;
    color: var(--primary-color);
  }

  .nextProjectBtn:active {
    border-color: var(--secondary-color);
    color: var(--primary-color);
  }

  .nextProjectBtn {
    background-color: var(--primary-color);
    border: 2px solid transparent;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;

  }

  .container img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Oder 'contain' je nach Bedarf */
  }

  .imageContainer {
    height: max-content;
    margin: 10px;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 10px;
  }
}
