:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}

#burgerButton {
  position: relative;
  bottom: 11vh;
  left: 22vw;
  display: none;
}

@media only screen and (max-width: 480px) {
  :root {
    --primary-color: #695fff;
    --secondary-color: #efeff8;
    --accent-color: #4d5567;
  }
  #burgerButton {
    position: relative;
    bottom: 13vh;
    left: 66vw;
    display: flex;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; /* Änderung hier */
    background-color: var(--primary-color);
    z-index: 999;
    transition: height 0.3s ease;
  }

  .overlay a {
    position: relative;
    top: 0vh;
  }

  .burgerMenu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: transform 0.3s ease;
    z-index: 999;
  }

  .line {
    width: 30px;
    height: 3px;
    background-color: var(--primary-color);
    margin: 5px 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  #closeButton {
    position: relative;

    top: 5vw;
    left: 40vw;
    z-index: 999;
  }
}


@media only screen and (max-width: 375px) {
  :root {
    --primary-color: #695fff;
    --secondary-color: #efeff8;
    --accent-color: #4d5567;
  }
  #burgerButton {
    position: relative;
    bottom: 16vh;
    left: 66vw;
    display: flex;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; /* Änderung hier */
    background-color: var(--primary-color);
    z-index: 999;
    transition: height 0.3s ease;
  }

  .overlay a {
    position: relative;
    top: 0vh;
  }

  .burgerMenu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: transform 0.3s ease;
    z-index: 999;
  }

  .line {
    width: 30px;
    height: 3px;
    background-color: var(--primary-color);
    margin: 5px 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  #closeButton {
    position: relative;

    top: 5vw;
    left: 40vw;
    z-index: 999;
  }
}
