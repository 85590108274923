.navigation li {
  text-decoration: none;
  list-style-type: none;
  font-size: 1.5rem;
  bottom: 5vh;
  display: inline;
  margin: 15px;
  color: rgb(148, 148, 148);
  /* background-color: aliceblue; */
  padding: 0;
  /* background-color: azure; */
}

.navigation li button {
  text-decoration: none;
  list-style-type: none;
  /* color: rgb(166, 28, 28); */
  font-size: 1.5rem;
  bottom: 7vh;
  position: relative;
}

.navigation li a {
  text-decoration: none;
  list-style-type: none;
  color: rgb(148, 148, 148);
  font-size: 1.5rem;
  bottom: 7vh;
}

@keyframes underline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.navigation {
  text-decoration: none;
  list-style-type: none;
  height: 6vh;
  padding: 20px;
  width: 100vw;
  color: rgb(148, 148, 148);
  /* background-color: #695fff; */
  background-color: #efeff8;
  box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.1);
}

.navigation li a {
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.navigation li a::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1.5px; /* oder die gewünschte Höhe deiner Linie */
  background-color: rgb(148, 148, 148); /* oder deine gewünschte Farbe */
  transition: width 0.3s ease; /* für einen sanften Übergang */
}

.navigation li a:hover::after {
  /* animation: underline 0.3s ease; */
  width: 100%;
}

.navigation p {
  position: relative;
  left: 46vw;
  bottom: 2vh;
  user-select: none;
  font-size: 1.5rem;
}

@media only screen and (max-width: 480px) {
  .navigationHide {
    display: none;
    opacity: 0;
  }
  .navigation li {
    text-decoration: none;
    list-style-type: none;
    display: none;
    display: inline;
    margin: 10px;
    color: white;
    /* background-color: aliceblue; */
    padding: 0;
    /* background-color: azure; */
  }

  .navigation li a {
    text-decoration: none;
    list-style-type: none;
    color: white;
  }

  @keyframes underline {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  .navigation {
    position: sticky;
    top: 0;
    overflow: hidden;
    display: flex;
    text-decoration: none;
    list-style-type: none;
    height: 11vh;
    padding: 20px;
    width: 100vw;
    z-index: 999;

    /* background-color: green; */
    /* box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 1); */
  }

  .navigation li {
    position: relative;
    font-size: 1rem;
    top: 5vh;
  }

  .navigation li a {
    display: inline-block;
    position: relative;
    text-decoration: none;
  }

  .navigation li a::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px; /* oder die gewünschte Höhe deiner Linie */
    background-color: white; /* oder deine gewünschte Farbe */
    transition: width 0.3s ease; /* für einen sanften Übergang */
  }

  .navigation li a:hover::after {
    /* animation: underline 0.3s ease; */
    width: 100%;
  }

  .navigation p {
    position: relative;
    left: 45vw;
    user-select: none;
    display: none;
  }
}
