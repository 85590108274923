:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}

.heading {
  color: var(--primary-color);
  margin-top: 10px;
}

.subHeading {
  display: flex;
  justify-content: center;
}

.content {
  font-size: 1rem;
  background-color: white;
  width: 50vw;
  border-radius: 50px;
  height: max-content;
  color: black;
  /* background-color: red; */
  display: flex; /* Änderung: Display auf flex setzen */
  flex-direction: column; /* Änderung: Flexbox-Ausrichtung auf Spalte setzen */
  align-items: center;
}

.content p {
  margin: 30px;
}

.boldText {
  font-weight: 700;
}

.container {
  justify-content: center;
  display: flex;
  text-align: start;
  align-items: center;
  margin-bottom: 200px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%; /* Füge diese Zeile hinzu, um die volle Breite zu nehmen */
  /* background-color: var(--secondary-color); */
}

@media only screen and (max-width: 480px) {
  :root {
    --primary-color: #695fff;
    --secondary-color: #efeff8;
    --accent-color: #4d5567;
  }

  .heading {
    color: var(--primary-color);
    margin-bottom: 30px;
 
  }

  .subHeading {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
 
  }

  .content {
    font-size: 1rem;
    background-color: white;
    width: 100vw;
    border-radius: 50px;
    height: max-content;
    color: black;
    /* background-color: red; */
    display: flex; /* Änderung: Display auf flex setzen */
    flex-direction: column; /* Änderung: Flexbox-Ausrichtung auf Spalte setzen */
    align-items: center;

  }

  .content p {
    margin: 10px;
  }

  .container {
    justify-content: center;
    display: flex;
    text-align: start;
    align-items: center;
    margin-bottom: 100px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%; /* Füge diese Zeile hinzu, um die volle Breite zu nehmen */
    /* background-color: var(--secondary-color); */
  }
}
