.containerHome {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentHome {
  border-radius: 50px;
  z-index: 10;
  position: relative;
  /* margin-top: 5vh; */
  background-color: #ffffff;
  width: 60vw;
  height: max-content;
  padding: 2%;
  transition: transform 0.3s, box-shadow 0.3s;
  /* background-color: red; */
  margin-bottom: 100px;

  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.1);
}

.flexContainer {
  display: flex;
  width: 100%;
  /* background-color: green; */
  justify-content: center;
}

.imageHome {
  width: 50%;
  border-bottom: 2px solid var(--primary-color);
  z-index: 1; /* Hebe das Bild über den Text */
  margin-right: 20px; /* Füge einen Abstand zwischen dem Bild und dem Text hinzu */
  /* background-color: blue; */
  transition: transform 0.3s;
}

.imageHome:hover {
  transform: scale(1.02);
}

.aboutMe {
  color: #171717;
  /* background-color: orange; */
  font-size: 1rem;
  text-align: left !important;
  display: flex;
  align-items: center; /* Hinzugefügt, um das aboutMe-Element zu zentrieren */
  flex: 1;
}

.footer {
  position: relative;
  z-index: 2; /* Setze den Footer über den Inhalt */
}

.heading {
  color: var(--primary-color);
  margin-top: 10px;
  margin-bottom: 30px;
}

.techElementsContainer {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background-color: purple; */
}

#headingTech {
  color: black;
  margin-bottom: 15px;
}

.techElements:hover {
  transform: scale(1.1);
}

.techElements {
  background-color: var(--primary-color);
  border-radius: 20px;
  margin: 5px;
  padding: 12px;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  display: inline-block;
  transition: transform 0.3s;
  user-select: none;
  align-content: flex-start;
  flex-direction: row;
}

@media only screen and (max-width: 480px) {
  .containerHome {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contentHome {
    border-radius: 50px;
    z-index: 10;
    position: relative;
    /* margin-top: 5vh; */
    background-color: #ffffff;
    width: 100vw;
    height: max-content;
    padding: 2%;
    transition: transform 0.3s, box-shadow 0.3s;
    /* background-color: red; */
    margin-bottom: 100px;

    justify-content: center;
    align-items: center;
    box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.1);
  }

  .flexContainer {
    display: flex;
    width: 100%;
    /* background-color: green; */
    justify-content: center;
  }

  .imageHome {
    width: 50%;
    border-bottom: 2px solid var(--primary-color);
    z-index: 1; /* Hebe das Bild über den Text */
    margin-right: 20px; /* Füge einen Abstand zwischen dem Bild und dem Text hinzu */
    /* background-color: blue; */
    transition: transform 0.3s;
  }

  .imageHome:hover {
    transform: scale(1.02);
  }

  .aboutMe {
    color: #171717;
    /* background-color: orange; */
    font-size: 1rem;
    text-align: left !important;
    display: flex;
    align-items: center; /* Hinzugefügt, um das aboutMe-Element zu zentrieren */
    flex: 1;
    margin-left: 15px;
    margin-right: 15px;
  }

  .footer {
    position: relative;
    z-index: 2; /* Setze den Footer über den Inhalt */
  }

  .heading {
    color: var(--primary-color);
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .techElementsContainer {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* background-color: purple; */
  }

  #headingTech {
    color: black;
    margin-bottom: 15px;
  }

  .techElements:hover {
    transform: scale(1.1);
  }

  .techElements {
    background-color: var(--primary-color);
    border-radius: 20px;
    margin: 5px;
    padding: 12px;
    font-size: 1rem;
    color: white;
    text-decoration: none;
    display: inline-block;
    transition: transform 0.3s;
    user-select: none;
    align-content: flex-start;
    flex-direction: row;
  }
}
