


:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}


.heading {

  color: var(--primary-color);
  margin-top: 10px;
}

.card {
  color: #d3d9e7;
  width: 20vw;
  height: 30vh;
  background-color: #695fff;
  text-align: center;
  /* position: relative; */
  /* margin-bottom: 2.5%; */
  /* margin-left: 1.5%; */
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  /* background-color: yellow; */
  margin: 10px;
  transition: transform 0.3s, box-shadow 0.3s;

}

.card p {

  padding-bottom: 12px;
  display: none;
}


.cardListTitle {

  font-size: 1.5rem;
  color: black;
  bottom: 5px;
  position: relative;
  user-select: none;
  
}

.card:hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.card img {
  transition: opacity 0.3s;
}

.card:hover img {
  opacity: 0.8;
}

.link {
  text-decoration: none;
}

.cardImage {
  width: 35vw;
  position: relative;
  right: 38%;
  bottom: 15%;
  height: auto;
  align-items: center;
  justify-content: center;
}

/* body  {
  overflow-x: hidden;
  overflow-y: visible;
} */

.cardList {
  background-color: none;
  width: 60vw;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  /* min-height: 100vh; */
  /* background-color: green; */
  margin: 10px;
   /* background-color: red; */
  }

.container {
  width: 100vw;
  height: max-content;
  background-color: none;
  display: flex;
  justify-content: center;
  margin-bottom: 10vh;
  background-color: var(--secondary-color);
}


@media only screen and (max-width: 480px) {
 


:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}


.card {
  padding: 20px;
  color: #d3d9e7;
  width: 95vw;
  height: 45vh;
  background-color: transparent;
  text-align: center;
  border-radius: 0px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  margin: 10px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid black;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card p {

  padding-bottom: 12px;
}

.cardListTitle {

  font-size: 1.5rem;
  color: black;
  bottom: 5px;
  position: relative;
  user-select: none;
  
}

.card:hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.card img {
  transition: opacity 0.3s;
}

.card:hover img {
  opacity: 0.8;
}

.link {
  text-decoration: none;
}

.cardImage {
  width: 220vw;
  position: relative;
  right: 55vw;
  bottom: 5%;
  align-items: center;
  justify-content: center;
}

/* body  {
  overflow-x: hidden;
  overflow-y: visible;
} */

.cardList {
  background-color: none;
  width: 100vw;
  /* height: 10vh; */
  border-radius: 5px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(1, 1fr);
  /* min-height: 100vh; */
  /* background-color: green; */
  margin: 10px;
   /* background-color: red; */
  }

.container {
  width: 100vw;
  height: max-content;
  background-color: none;
  display: flex;
  justify-content: center;
  margin-bottom: 10vh;
  background-color: var(--secondary-color);
}


}