
:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}




.App {
  text-align: center;
  background-color: var(--secondary-color);
  min-height: 100vh;
}

body {
  overflow-x: hidden;
  background-color: var(--secondary-color);
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}




@media (prefers-reduced-motion: no-preference) {

}

.App-header {
  /* background-color: #282c34; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


/* Breite der Scrollleiste */
::-webkit-scrollbar {
  width: 20px;
  border-color: 1px solid #695fff;

}

/* Hintergrund der Scrollleiste */
::-webkit-scrollbar-track {
  background-color: #695fff;
  border: 1px solid #555; /* Border hinzufügen */
 
}

/* Farbe des Scrollbalkens selbst */
::-webkit-scrollbar-thumb {
  background-color: white;
  border: 1px solid #555; /* Border hinzufügen */
  border-radius: 10px; /* Runde Ecken */
}


