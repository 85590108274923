:root {
  --primary-color: #695fff;
  --secondary-color: #efeff8;
  --accent-color: #4d5567;
}

.heading {
  color: var(--primary-color);
  margin-top: 10px;
  margin-bottom: 30px;
}

.peaceImage {
  height: 20vw;
  width: auto;
  border-bottom: 2px solid var(--primary-color);
  /* background-color: green; */
  display: block; /* Änderung: Display auf block setzen */
  margin: auto;
 transition: transform 0.3s, box-shadow 0.3s;
}

.peaceImage:hover {
transform: scale(1.02);
}



.subHeading {
  display: flex;
  justify-content: center;
}

.content {
  font-size: 1rem;
  background-color: white;
  width: 50vw;
  border-radius: 50px;

  height: max-content;
  color: black;
  /* background-color: red; */
  display: flex; /* Änderung: Display auf flex setzen */
  flex-direction: column; /* Änderung: Flexbox-Ausrichtung auf Spalte setzen */
  align-items: center;
  box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.1);
}

.content p {
  margin: 30px;
}

.boldText {
  font-weight: 700;
}

.container {
  justify-content: center;
  display: flex;
  text-align: start;
  align-items: center;
  margin-bottom: 100px;

}

@media only screen and (max-width: 480px) {
  :root {
    --primary-color: #695fff;
    --secondary-color: #efeff8;
    --accent-color: #4d5567;
  }

  .heading {
    color: var(--primary-color);
    margin-top: 10px;
  }

  .peaceImage {
    height: 50vw;
    width: auto;
    border-bottom: 2px solid var(--primary-color);
    /* background-color: green; */
    display: block; /* Änderung: Display auf block setzen */
    margin: auto;
  }
  .subHeading {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
  }

  .content {
    font-size: 1rem;
    background-color: white;
    width: 100vw;
    border-radius: 50px;
    height: max-content;
    color: black;
    /* background-color: red; */
    display: flex; /* Änderung: Display auf flex setzen */
    flex-direction: column; /* Änderung: Flexbox-Ausrichtung auf Spalte setzen */
    align-items: center;
  }

  .content p {
    margin: 10px;
  }

  .boldText {
    font-weight: 700;
  }

  .container {
    justify-content: center;
    display: flex;
    text-align: start;
    align-items: center;
    margin-bottom: 100px;
  }
}
